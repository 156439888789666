<template>
  <div class="account-settings-info-right">
    <div class="account-settings-info-title">
      <span>{{selectedMenu.meta.title}}</span>
    </div>
    <div class="panel">
      <div ref="treeContent">
        <div class="tree-panel">
          <z-tree ref="treeRef"
                  :load-url="treeLoadUrl"
                  @deleteClick="treeDeleteClickHandle"
                  @addClick="treeAddClickHandle"
                  @treeNodeClick="treeNodeClickHandle"
                  @dropNode="handleDrop"></z-tree>
        </div>
      </div>
      <div class=content-panel>
        <div class="detail-content-panel">
          <div class="panel-body">
            <div class="panel-body-title">
              <span>字典详情</span>
            </div>
            <dictionary-form ref="editRef"
                             @editSuccess="handleEditSuccess"></dictionary-form>
          </div>
        </div>
      </div>
      <a-modal
        title="资源添加"
        v-model="visible"
        okText="添加"
        cancelText="取消"
        width="960px"
        :confirmLoading="addStatus"
        @ok="handleOk">
        <dictionary-form ref="addRef"
                         type="add"
                         @addSuccess="handleAddSuccess"
                         @addError="handleAddError"
                         @addStart="handleAddStart"
        ></dictionary-form>
      </a-modal>
    </div>
  </div>
</template>
<script>

  import SERVICE_URLS from '../../../api/service.url'
  import ZTree from '../../../components/ztree/index'
  import DictionaryForm from './DictionaryForm'
  import menuTile from '../../common/mixins/menuTitle'

  export default {
    name: 'ResourceSystemSetting',
    mixins: [menuTile],
    data() {
      return {
        treeLoadUrl: SERVICE_URLS.dictionary.tree,
        visible: false,
        addStatus: false
      }
    },
    methods: {
      handleDrop (dropNode, targetNode, moveType) {
        if (dropNode && targetNode && moveType) {
          this.$http(this, {
            url: SERVICE_URLS.dictionary.moveNode,
            data: {
              dropNodeId: dropNode.id,
              targetNodeId: targetNode.id,
              moveType
            },
            successTipsContent: '拖拽更新成功',
            success: () => {
              this.$refs.treeRef.loadTree()
            }
          })
        }
      },
      treeNodeClickHandle(treeNode) {
        this.$refs['editRef'].loadData(treeNode.id)
      },
      treeDeleteClickHandle(treeNode) {
        this.showDeleteConfirm(treeNode)
      },
      treeAddClickHandle(treeNode) {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['addRef'].loadSelect(treeNode.id)
        })
      },
      handleOk() {
        this.$refs['addRef'].submitAdd()
      },
      handleAddStart() {
        this.addStatus = true
      },
      handleEditSuccess(value) {
        this.$refs['treeRef'].updateSelectNode(value)
      },
      handleAddSuccess(value) {
        this.$refs['treeRef'].addNode(value)
        this.visible = false
      },
      handleAddError() {
        this.addStatus = false
      },
      showDeleteConfirm(treeNode) {
        this.$confirm({
          title: '确定删除当前字典？',
          content: '会自动删除所有子节点，请谨慎操作！',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.deleteDelete(treeNode, resolve)
            }).catch(() => this.$error('Oops errors!'))
          }
        })
      },
      deleteDelete(treeNode, resolve) {
        this.$http(this, {
          url: SERVICE_URLS.dictionary.delete,
          params: {
            id: treeNode.id
          },
          success: () => {
            this.$refs['treeRef'].deleteNode(treeNode)
            resolve()
          }
        })
      }
    },
    components: { ZTree, DictionaryForm }
  }
</script>